<template>
    <div id="UploadStockPreOrder" class="container-fluid m-0 p-0">
        <div class="row m-0 p-0 justify-content-center">
            <div class="col-12 col-lg-12 m-0 p-0 shadow   bg-white rounded">
                <div class="row m-0 p-4">
                    <div class="col-12 col-xl-2">
                        <div class="input-group-prepend input-group-sm">
                            <select class="custom-select" v-model="isSaleSource">
                                <option v-for="(e, i) in selectSaleSource" :key="i" :value="e.key">
                                    {{ e.label }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-12 col-xl-3">
                        <b-form-file accept=".xlsx , .xls" v-model="file1" :state="Boolean(file1)" size="sm"
                            placeholder="เลือกไฟล์ที่ต้องการอัพโหลด" drop-placeholder="Drop file here..."
                            @change="onChange"></b-form-file>
                    </div>
                    <div class="col-12 col-xl-4 ">
                        <button type="button" class="btn btn-sm bt-main" @click="Upload()">
                            อัพโหลดข้อมูล
                        </button>
                        <button type="button" class="ml-1 btn-sm btn bt-TWD" @click="ClearData()">
                            ยกเลิก
                        </button>
                        <button type="button" class="ml-1 btn btn-sm bt-BNB">
                            <a download class=" text-white" @click="SampleFile()">
                                ดาวน์โหลดไฟล์ </a>
                        </button>
                    </div>
                </div>
                <div class="row m-0 pl-4 pb-4">
                    <div class="col-12 mx-0 mb-xl-0">
                        <p class="px-0 font-700 w-100 font-0-9s" style="display: flex">
                            จำนวน &nbsp;
                            <span class="text-main">
                                {{ new Intl.NumberFormat().format(totalRows) }}
                            </span>
                            <span class="ml-2">รายการ</span>
                            <span class="ml-2 text-twd">*** การอัพโหลดไฟล์จะถูกแทนที่ข้อมูลเดิมทั้งหมด</span>

                        </p>
                        <div v-if="isLoading" class="col-12 col-xl-12 m-0 p-0 text-center">
                            <div class="p-xl-5 m-xl-5">
                                <b-spinner variant="info" small type="grow"></b-spinner>
                                <b-spinner variant="info" small type="grow"></b-spinner>
                                <b-spinner variant="info" small type="grow"></b-spinner>
                            </div>
                        </div>
                        <div v-else>
                            <b-table hover outlined show-empty class="form-control-sm font-0-7s" :items="items"
                                :fields="fields" head-variant="light" :current-page="page_num" :per-page="page_size">
                            </b-table>
                        </div>
                        <div class="col-12">
                            <div class="row justify-content-end">
                                <div class="col-6 col-xl-2 input-group-prepend input-group-sm">
                                    <select class="custom-select" v-model="page_size">
                                        <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                                            {{ e }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-6 col-xl-3">
                                    <b-pagination v-model="page_num" :total-rows="totalRows" :per-page="page_size"
                                        align="fill" size="sm" class="my-0"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import XLSX from "xlsx";

export default {
    name: "UploadStockPreOrder",
    data() {
        return {
            file1: null,
            items: null,
            fields: [
                { key: "skuId", label: "รหัสสินค้า", sortable: true, class: "text-center" },
                { key: "stock", label: 'คลังสินค้า', sortable: true, class: "text-center" },
                { key: "DateStart", label: "เริ่มต้น", sortable: true, class: "text-center" },
                { key: "DateEnd", label: "สิ้นสุด", sortable: true, class: "text-center" },
            ],
            page_size: 50,
            page_size_list: [50, 100],
            page_num: 1,
            totalRows: 0,
            isLoading: false,
            selectedCell: null,
            selectSaleSource: [
                { key: 'SP', label: 'Shopee' },
                { key: 'LZ', label: 'Lazada' }
            ],
            isSaleSource: 'SP',
        }
    },
    computed: {
        editedCellValue() {
            if (this.selectedCell) {
                const { row, field } = this.selectedCell;
                return row[field]; // Get the current value of the selected cell
            }
            return null;
        },
    },
    methods: {
        async onChange() {
            try {
                this.file = event.target.files ? event.target.files[0] : null;
                let fname = this.file.name;
                fname = fname.toLowerCase();
                if (fname.indexOf(".xls") < 0) {
                    throw `กรุณาตรวจสอบ format ไฟล์!!! file  ${fname}  is not excel file (.xls or .xlsx).`;
                }
                if (this.file) {

                    const reader = new FileReader();
                    this.isLoading = true;
                    reader.onload = (e) => {
                        const bstr = e.target.result;
                        const wb = XLSX.read(bstr, { type: "binary" });
                        const wsname = wb.SheetNames[0];
                        const ws = wb.Sheets[wsname];
                        let rows = XLSX.utils.sheet_to_row_object_array(ws);

                        var jsonData = rows.map((row) => ({
                            skuId: String(row.skuId).trim(),
                            stock: String(row.stock).trim(),
                            DateStart: String(row.DateStart).trim(),
                            DateEnd: String(row.DateEnd).trim(),
                        }));

                        let findBlanks = jsonData.find(
                            (itm) =>
                                itm.skuId == "" ||
                                itm.skuId == "undefined" ||
                                itm.skuId == "#VALUE!" ||
                                itm.stock == "" ||
                                itm.stock == "undefined" ||
                                itm.stock == "#VALUE!" ||
                                itm.DateStart == "" ||
                                itm.DateStart == "undefined" ||
                                itm.DateStart == "#VALUE!" ||
                                itm.DateEnd == "" ||
                                itm.DateEnd == "undefined" ||
                                itm.DateEnd == "#VALUE!"
                        );
                        if (findBlanks) {
                            this.$serviceMain.showErrorAlert(
                                this,
                                `กรุณาตรวจสอบไฟล์ พบค่าว่าง ค่า null ค่า #VALUE! ใน รหัสสินค้า หรือ คลังสินค้า หรือ วันที่เริ่มต้น หรือ วันที่สิ้นสุด ในไฟล์`
                            );
                        }

                        this.items = jsonData;
                        this.totalRows = this.items.length;
                        this.isLoading = false;
                    }
                    reader.readAsBinaryString(this.file);
                    this.isLoading = false;
                }
            } catch (error) {
                this.$serviceMain.showErrorAlert(
                    this,
                    error.message == undefined ? error : error.message
                );
            }
        },
        async ClearData() {
            this.file1 = null;
            this.items = null;
            this.itemlist = null;
            this.totalRows = 0;
        },
        async Upload() {
            try {

                const saleSourceLabel = await this.getSaleSourceLabel();

                if (this.items == null) throw `กรุณาเลือกไฟล์ที่ต้องการอัพโหลดก่อน / Please select file to upload first`;

                let confirm = await this.$serviceMain.showConfirmAlert(
                    this,
                    `คุณต้องการอัพโหลดข้อมูลสินค้า Pre-Order ของ ${saleSourceLabel} ใช่หรือไม่? / Do you want to upload Pre-Order products data of ${saleSourceLabel}?`
                );

                if (!confirm) return;

                const payload = {
                    saleSource: this.isSaleSource,
                    jsonData: this.items
                };

                const getAPI = await this.$serviceAPI.call_API(
                    "post",
                    `market-place/uploadStockPreOrder`,
                    payload,
                    1
                );
                if (getAPI.status_code === 200) {
                    this.$serviceMain.showSuccessAlert(
                        this,
                        "อัพโหลดข้อมูลสำเร็จ / Complete"
                    );
                    this.$emit("uploadSuccess");
                    await this.ClearData();
                } else {
                    throw 'อัพโหลดข้อมูลไม่สำเร็จ / Upload data failed'
                }
            } catch (error) {
                this.$serviceMain.showErrorAlert(
                    this,
                    error.message == undefined ? error : error.message
                );
            }
        },
        async SampleFile() {
            try {
                const saleSourceLabel = await this.getSaleSourceLabel();

                const payloads = {
                    saleSource: this.isSaleSource
                }
                let getAPI = await this.$serviceAPI.call_API(
                    "post",
                    `market-place/getListStockPreOrder`,
                    payloads,
                    1
                );
                if (getAPI && getAPI.data.length > 0) {
                    this.$serviceMain.JsonToExcat(getAPI.data, `StockPreOrder_${saleSourceLabel}.xlsx`);
                } else {
                    throw `ไม่พบข้อมูลสินค้า Pre-Order ของ ${saleSourceLabel} / No Pre-Order products data of ${saleSourceLabel} found`;
                }
            } catch (error) {
                this.$serviceMain.showErrorAlert(
                    this,
                    error.message == undefined ? error : error.message
                );
            }
        },
        async getSaleSourceLabel() {
            const saleSource = this.selectSaleSource.find(source => source.key === this.isSaleSource);
            return saleSource ? saleSource.label : 'Unknown Source';
        }
    }
}
</script>
