<template>
    <div id="checkStockPreOrder" class="container-fluid m-0 p-0">
        <div class="row m-0 p-0 justify-content-center">
            <div class="col-12 col-lg-12 m-0 p-0 shadow   bg-white rounded">
                <div class="row m-0 p-4">
                    <div class="col-12 col-lg-6">
                        <div class="input-group input-group-sm mb-3">
                            <div class="input-group-prepend input-group-sm">
                                <select class="custom-select" v-model="mode">
                                    <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                                        {{ e.name }}
                                    </option>
                                </select>
                            </div>
                            <input type="search" class="form-control" placeholder="ค้นหา / Seach" v-model="find"
                                v-on:keyup.enter="searchValueFromObject()" />
                            <button type="button" class="btn bt-main btn-sm mx-1 px-4" @click="searchValueFromObject()">
                                <span class="mx-1" v-html="$svg_icon.search"></span>ค้นหา
                            </button>
                        </div>
                    </div>
                </div>
                <div class="row m-0 pl-4 pb-4">
                    <div class="col-12 mx-0 mb-3 mb-xl-0">
                        <p class="px-0 font-700 w-100 font-0-9s" style="display: flex">
                            จำนวน &nbsp;
                            <span class="text-main">
                                {{ new Intl.NumberFormat().format(totalRows) }}
                            </span>
                            <span class="ml-2">รายการ</span>
                        </p>
                        <div v-if="isLoading" class="col-12 col-xl-12 m-0 p-0 text-center">
                            <div class="p-xl-5 m-xl-5">
                                <b-spinner variant="info" small type="grow"></b-spinner>
                                <b-spinner variant="info" small type="grow"></b-spinner>
                                <b-spinner variant="info" small type="grow"></b-spinner>
                            </div>
                        </div>
                        <div v-else>
                            <b-table hover outlined show-empty class="form-control-sm font-0-7s" :items="items"
                                :empty-text="'ไม่พบข้อมูล / No Data'" :fields="fields" head-variant="light"
                                :current-page="page_num" :per-page="page_size">
                                <template #cell(stock)="row">
                                    <div>
                                        <input class="form-control form-control-sm text-center" type="number"
                                            v-model="row.item.stock" />
                                    </div>
                                </template>
                                <template #cell(DateStart)="row">
                                    <div>
                                        <input class="form-control form-control-sm text-center" type="date"
                                            v-model="row.item.DateStart" />
                                    </div>
                                </template>
                                <template #cell(DateEnd)="row">
                                    <div>
                                        <input class="form-control form-control-sm text-center" type="date"
                                            v-model="row.item.DateEnd" />
                                    </div>
                                </template>
                                <template #cell(Actions)="row">
                                    <span type="button" class="text-bnb font-1-2s bold"
                                        @click="updateStock(row.item, 'Update')">บันทึก
                                    </span>

                                    <span type="button" class="ml-3 text-twd font-1-2s bold"
                                        @click="updateStock(row.item, 'Delete')">ลบ
                                    </span>

                                </template>
                                <!-- <template #cell(DateStart)="row">
                                    <span v-if="!row.editing">
                                        {{ row.DateStart }}
                                    </span>
                                    <template v-else>
                                        <input v-model="row.DateStart" type="date" />
                                        <div class="text-right">
                                            <button class="btn btn-sm btn-success"
                                                @click="saveEdit(row, 'DateStart')">Save</button>
                                            <button class="btn btn-sm btn-secondary"
                                                @click="cancelEdit(row, 'DateStart')">Cancel</button>
                                        </div>
                                    </template>
                                </template> -->

                            </b-table>
                        </div>

                        <div class="col-12">
                            <div class="row justify-content-end">
                                <div class="col-6 col-xl-2 input-group-prepend input-group-sm">
                                    <select class="custom-select" v-model="page_size">
                                        <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                                            {{ e }}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-6 col-xl-3">
                                    <b-pagination v-model="page_num" :total-rows="totalRows" :per-page="page_size"
                                        align="fill" size="sm" class="my-0"></b-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "TableListProduct",
    props: {
        saleSource: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            mode: "skuId",
            find: '',
            isLoading: false,
            items: [],
            itemlist: [],
            totalRows: 0,
            selected: null,
            page_size: 25,
            page_size_list: [25, 50, 100],
            page_num: 1,
            select_mode: [
                { key: 'skuId', name: 'รหัสสินค้า' },
            ],
            fields: [
                { key: "skuId", label: "SKU", class: "text-center", sortable: true, thStyle: { width: "20%" } },
                { key: 'stock', label: 'Stock', class: "text-center", sortable: true, thStyle: { width: "20%" } },
                { key: 'DateStart', class: "text-center", sortable: true, editable: true, thStyle: { width: "20%" }, editable: true },
                { key: 'DateEnd', class: "text-center", sortable: true, editable: true, thStyle: { width: "20%" }, editable: true },
                { key: 'Actions', class: "text-center", thStyle: { width: "20%" } }
            ],
        }
    },
    async mounted() {
        this.isLoading = true;
        await this.initTable();
        this.isLoading = false;

    },
    methods: {
        async initTable() {
            try {
                const payloads = {
                    saleSource: this.saleSource
                }
                let getAPI = await this.$serviceAPI.call_API(
                    "post",
                    `market-place/getListStockPreOrder`,
                    payloads,
                    1
                );
                this.items = getAPI.data
                this.itemlist = getAPI.data;
                this.totalRows = this.items.length;
            } catch (error) {
                this.$serviceMain.showErrorAlert(
                    this,
                    error.message == undefined ? error : error.message
                );
            }

        },
        async searchValueFromObject() {
            try {
                // console.log(this.find);
                this.items = this.itemlist.filter(item => {
                    const modeValue = item[this.mode];
                    if (typeof modeValue === 'string') {
                        return modeValue.toLowerCase().includes(this.find.toString().toLowerCase());
                    }
                    return false; // or true/false depending on how you want to handle non-string values
                });
                this.totalRows = this.items.length;
            } catch (error) {
                this.$serviceMain.showErrorAlert(
                    this,
                    error.message == undefined ? error : error.message
                );
            }

        },
        async unSearchValueFromObject() {
            this.items = this.itemlist;
            this.totalRows = this.items.length;
        },
        async updateStock(row, mode) {
            try {
                const skuId = String(row.skuId).trim();
                const stock = String(row.stock).trim();
                const DateStart = String(row.DateStart).trim();
                const DateEnd = String(row.DateEnd).trim();

                const payloads = {
                    mode: mode,
                    saleSource: this.saleSource,
                    jsonData: {
                        skuId: skuId,
                        stock: stock,
                        DateStart: DateStart,
                        DateEnd: DateEnd
                    }
                }

                let confirm = await this.$serviceMain.showConfirmAlert(
                    this,
                    `SKU : ${skuId} ยืนยันการบันทึกข้อมูล / Confirm Save`
                );
                if (!confirm) return;

                await this.$serviceAPI.call_API(
                    "post",
                    `market-place/updateStockPreOrder`,
                    payloads,
                    1
                );

                this.$serviceMain.showSuccessAlert(
                    this,
                    "อัพโหลดข้อมูลสำเร็จ / Complete"
                );

                this.isLoading = true;
                await this.initTable();
                this.isLoading = false;
            } catch (error) {
                this.$serviceMain.showErrorAlert(
                    this,
                    error.message == undefined ? error : error.message
                );
            }

        },
    },
    watch: {
        "find": function (v) {
            if (v === "") {
                this.unSearchValueFromObject();
            }
        }
    },
}
</script>
